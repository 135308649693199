<template :routePreventDialog="routePreventDialog" :customClass="'leave-update'">
	<div v-if="drawer">
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 99"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="form-title my-auto" v-if="leaveCreate.id > 0">Update Leave</div>
					<div class="form-title my-auto" v-else>Create Leave</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						@click="CreateOrUpdateLeave()"
					>
						Save
					</v-btn>
				</v-flex>
				<v-form
					ref="leaveForm"
					id="leaveForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="CreateOrUpdateLeave"
				>
					<div class="drawer-content pt-5 px-5">
						<div
							class="d-form-grp mb-3"
							v-if="AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])"
						>
							<label class="input-label required" required>Select Staff </label>
							<v-autocomplete
								depressed
								hide-details
								outlined
								placeholder="Select Staff"
								:items="userList"
								:disabled="formLoading"
								:loading="formLoading"
								item-text="display_name"
								item-value="id"
								@change="getCategoryOptions"
								v-model.trim="leaveCreate.staff"
								:rules="[vrules.required(leaveCreate.staff, 'Staff')]"
								:class="{
									required: !leaveCreate.staff,
								}"
							>
								<template v-slot:selection="data">
									<v-chip small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-avatar>
										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>
						<template v-if="leaveCreate && leaveCreate.staff != null">
							<div class="mb-3 d-flex blue lighten-5 pa-2">
								<div class="profile-img">
									<v-avatar size="100" class="mr-4">
										<img v-if="userData.profile_img" :src="userData.profile_img" />
										<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
									</v-avatar>
								</div>
								<div class="profile-desc">
									<div class="mb-2">
										<span class="fw-600">User # : </span>
										<span class="fw-500" v-if="userData && userData.barcode"
											><v-chip
												color="cyan"
												label
												outlined
												x-small
												class="text-capitalize"
												@click="goToUserDetail(userData.id)"
											>
												{{ userData.barcode }}
											</v-chip>
										</span>
									</div>
									<div class="mb-2">
										<span class="fw-600">Name : </span>
										<span class="fw-500" v-if="userData && userData.display_name">{{
											userData.display_name
										}}</span>
									</div>
									<!-- <div class="mb-2">
										<span class="fw-600">Email : </span>
										<span class="fw-500" v-if="userData && userData.display_name">{{ userData.email }}</span>
									</div>
									<div class="mb-2">
										<span class="fw-600">Phone Number : </span>
										<span class="fw-500" v-if="userData && userData.display_name">{{
											userData.phone_number
										}}</span>
									</div>
									<div class="mb-2">
										<span class="fw-600">Joining Date : </span>
										<span class="fw-500" v-if="userData && userData.joining_date">{{
											formatDate(userData.joining_date)
										}}</span>
									</div> -->
									<div class="mb-2">
										<span class="fw-600">Probation Period : </span>
										<span class="fw-500" v-if="userData && userData.joining_date && userData.provision_date"
											>{{ formatDate(userData.joining_date) }} <b>To</b>
											{{ formatDate(userData.provision_date) }}</span
										>
										<span class="fw-600 ml-2">
											<v-chip
												v-if="probisionTime >= 0"
												color="red"
												class="white--text"
												outlined
												style="letter-spacing: 0.8px"
												small
											>
												Probation {{ getProvision(userData.provision_date) }}
											</v-chip>
										</span>
									</div>
									<div class="mb-2">
										<span class="fw-600">Designation : </span>
										<v-chip
											color="primary"
											label
											outlined
											x-small
											class="ml-2 text-capitalize"
											v-if="userData && userData.profile"
										>
											<template v-if="userData.profile == 'email-marketer'">Email Marketer</template>
											<template v-else>{{ userData.profile }}</template>
										</v-chip>
									</div>
								</div>
							</div>
						</template>
						<div class="d-form-grp mt-4" v-if="leaveCreate.staff">
							<!-- <label class="input-label required" required> Leave Type</label> -->
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper" style="height: auto !important">
									<table class="w-100">
										<thead>
											<tr>
												<th>#</th>
												<th>Type</th>
												<th>Remaining</th>
												<th>Pending</th>
												<th>Approved</th>
											</tr>
										</thead>
										<!-- <pre>
											{{categoryList}}
										</pre> -->
										<tbody>
											<template v-for="(row, index) in categoryList">
												<tr :key="index">
													<td>
														<v-radio-group
															v-model="leaveCreate.category"
															row
															:disabled="row.balance - row.pending == 0 || row.balance - row.pending < 0"
															hide-details
															class="mt-0 test"
															@change="calculateDate(leaveCreate, 'category'), test(row)"
														>
															<v-radio label="" :value="row.category" class="mt-0 test-auto"></v-radio>
														</v-radio-group>
													</td>
													<td class="text-capitalize">{{ row.category.replace("-", " ") }}</td>
													<td>
														<span v-if="row.category == 'UnPaid'"></span>
														<span v-else-if="row.balance">{{ row.balance - row.pending }}</span>

														<span v-else>0</span>
													</td>
													<td>
														<span v-if="row.category == 'UnPaid'"></span>
														<span v-else-if="row.pending">{{ row.pending }}</span>
														<span v-else>0</span>
													</td>
													<td>
														<span v-if="row.category == 'UnPaid'"></span>
														<span v-else-if="row.taken">{{ row.taken }}</span>
														<span v-else>0</span>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
							</div>
							<!-- <div v-for="(row, index) in categoryList" :key="index">
								<span>
									<v-radio-group v-model="leaveCreate.category" row hide-details class="mt-0 test">
										<div>
											<v-radio
											label=""
											class="mt-0 test-auto"
											:value="row.category"
											v-on:change="categoryDays"
											v-on:click="categoryDays"
										></v-radio>
										</div>
										<v-chip color="primary" label outlined x-small class="ml-2" v-if="row && row.category">
											{{ row.category }}
											<template v-if="row.allocated_days">( {{ row.allocated_days }} )</template></v-chip
										>

										<v-chip color="red" label outlined x-small class="ml-2" v-if="row && row.balance">
											{{ "Remaining" }} ({{ row.balance }})</v-chip
										>
										<v-chip color="orange" label outlined x-small class="ml-2" v-if="row && row.balance">
											{{ "Pending" }} ({{ row.pending }})</v-chip
										>										<v-chip color="green" label outlined x-small class="ml-2" v-if="row">
											{{ "Approved" }} ( {{ row.taken }} )</v-chip
										>
									</v-radio-group>
								</span>
							</div> -->
						</div>
						<div class="d-form-grp mt-4">
							<label class="input-label required">Start Date</label>
							<DatePicker
								depressed
								:mandatory="true"
								:nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
								placeholder="Start Date"
								v-model="start_date"
								:min-date="minDate"
								:default-date="start_date"
								scrollable
								enjoyWeekEnd
								:rules="[vrules.required(start_date, 'Start Date')]"
								:class="{
									required: !start_date,
								}"
								@change="calculateDate(leaveCreate, 'start_date')"
							></DatePicker>
							<!--:holidays="holiday"-->
						</div>
						<div class="d-form-grp mt-4">
							<label class="input-label required">End Date</label>

							<template v-if="leaveCreate && leaveCreate.id > 0 && end_date == null">
								<DatePicker
									depressed
									:nullable="leaveCreate"
									placeholder="End Date"
									v-model.trim="end_date"
									:min-date="start_date"
									:disabled="blankdate && leaveCreate.category != 'UnPaid'"
									:max-date="maxDate"
									scrollable
									enjoyWeekEnd
									:default-date="end_date"
									:rules="[vrules.required(end_date, 'End Date')]"
									:class="{
										required: !end_date,
									}"
									v-on:change="calculateDate(leaveCreate)"
								></DatePicker>
								<!--:holidays="holiday"-->
							</template>
							<template v-else>
								<DatePicker
									depressed
									:nullable="leaveCreate && leaveCreate.id > 0 ? false : true"
									placeholder="End Date"
									v-model.trim="end_date"
									:min-date="start_date"
									:disabled="blankdate && leaveCreate.category != 'UnPaid'"
									:max-date="maxDate"
									:default-date="end_date"
									scrollable
									enjoyWeekEnd
									:rules="[vrules.required(end_date, 'End Date')]"
									:class="{
										required: !end_date,
									}"
									v-on:change="calculateDate(leaveCreate)"
								></DatePicker>
								<!-- :holidays="holiday"-->
							</template>
						</div>
						<div style="margin-bottom: 10px !important">
							<div class="d-form-grp mt-4 w-20">
								<label class="input-label">Half Day</label>
								<v-switch
									:disabled="diableDay"
									v-model="leaveCreate.duration"
									:value="leaveCreate.duration"
									dense
									hide-details
									class="ma-0"
									v-on:change="changeData(leaveCreate.duration)"
								></v-switch>
							</div>

							<div class="d-form-grp mt-4" v-if="leaveCreate.duration == 1">
								<label class="input-label"></label>
								<v-radio-group v-model="leaveCreate.duration_type" row dense hide-details class="mt-0">
									<v-radio label="First half" :value="1" class="mr-10"></v-radio>
									<v-radio label="Second half" :value="2" class="ma-0"></v-radio>
								</v-radio-group>
							</div>
							<div class="d-form-grp mt-4" v-if="leaveCreate.duration == 1 && start_date != end_date">
								<label class="input-label"></label>
								<v-radio-group disabled v-model="leaveCreate.day_type" row dense hide-details class="mt-0">
									<v-radio
										:label="`Start Date : ${formatDate(this.start_date)}`"
										:value="1"
										class="mr-5"
									></v-radio>
									<v-radio
										:label="`End Date : ${formatDate(this.end_date)}`"
										:value="2"
										class="ma-0"
									></v-radio>
								</v-radio-group>
							</div>
						</div>
						<div class="mt-2">
							<span style="font-weight: 600">Total Leave : </span>
							<span class="fw-500" style="font-weight: 600">
								<span class="primary--text" v-if="start_date && end_date">
									{{ getBusinessDays() }}
									<span v-if="probisionTime >= 0 || leaveCreate.categories == 'UnPaid'"> (Unpaid)</span>
								</span>
								<span v-else>0</span>
							</span>
						</div>
						<div class="mb-3">
							<div class="d-flex justify-content-between pr-5 pt-1">
								<div class="">Attachment</div>
								<div class="w-10 pr-3" style="margin-top: -12px">
									<v-tooltip top nudge-left="58">
										<template #activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 white--text"
												class="mt-3 ml-2"
												tile
												v-on="on"
												v-bind="attrs"
												depressed
												:disabled="files.length >= 5"
												v-on:click="addMore()"
											>
												<v-icon>mdi-plus</v-icon>
											</v-btn>
										</template>
										<span>Add upto 5 Attachments</span>
									</v-tooltip>
								</div>
							</div>
						</div>
						<template v-for="(file, index) in files">
							<div class="d-flex mb-2 pr-5" :key="index">
								<v-row>
									<v-col md="5" class="py-0 my-0 pr-0">
										<v-file-input
											:id="`document-file-${index}`"
											placeholder="Select File"
											outlined
											prepend-icon=""
											prepend-inner-icon="mdi-attachment"
											hide-details
											v-model="file.file"
											v-on:change="updateFile(index, $event)"
											v-on:click:clear="updateFile(index, $event)"
										></v-file-input>
									</v-col>
									<v-col md="5" class="py-0 my-0 pr-0">
										<TextInput
											:id="`document-name-${index}`"
											v-model="file.name"
											hide-details
											class="mt-0 pt-0"
											placeholder="File Name"
											:suffix="file.suffix"
										></TextInput>
									</v-col>
									<v-col md="2" class="py-0 my-0">
										<v-btn
											:disabled="files.length < 2"
											v-on:click="removeFile(index)"
											color="red lighten-1 white--text"
											icon
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<!-- <div class="w-40 pr-3" style="margin-top: -12px">
									
								</div>
								<div class="w-45 pr-3" style="margin-top: -12px">
									
								</div>
								<div class="w-10 pr-3" style="margin-top: -12px">
									
								</div> -->
							</div>
						</template>
						<div class="d-form-grp mt-4 mb-4">
							<label class="input-label">Reason for leave</label>
							<TextAreaInput
								hide-details
								placeholder="Reason for leave"
								v-model="leaveCreate.reason"
								:word-limit="500"
								:rows="5"
							></TextAreaInput>
						</div>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<style>
/* .bt-table .v-data-table__wrapper {
    height: auto;
    overflow: auto;
} */
</style>
<script>
import MainMixin from "@/core/mixins/main.mixin.js";
import DatePicker from "@/view/components/DatePicker";
import { POST, GET } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";
import TextAreaInput from "@/view/components/TextAreaInput";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { toSafeInteger, filter, find, toString } from "lodash";
import { getCurrentUser } from "@/core/services/jwt.service";
import TextInput from "@/view/components/TextInput";
import { LeaveEventBus } from "@/core/lib/leave.form.lib";
import objectPath from "object-path";

export default {
	mixins: [MainMixin],
	name: "CreateLeave",
	components: {
		DatePicker,
		TextInput,
		TextAreaInput,
	},
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		leaveUpdateData: {
			type: Object,
		},
		leaveIds: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			holiday: [],
			holidaysInBetween: 0,
			formLoading: false,
			probisionTime: 0,
			formValid: true,
			minDate: null,
			leave: null,
			updateDialog: false,
			apprveLeaves: 0,
			pendingLeaves: 0,
			allocated: 0,
			diableDay: false,
			pageLoading: false,
			userData: [],
			categoryList: new Array(),
			start_date: null,
			end_date: null,
			maxDate: null,
			weekEndDays: 0,
			userList: new Array(),
			remainingLeaves: null,
			blankdate: false,
			files: [
				{
					file: null,
					name: null,
				},
			],
			leaveList: [
				{
					category: null,
					maxdays: null,
					remainig_days: null,
				},
			],
			leaveCreate: new Object({
				id: null,
				staff: null,
				category: "UnPaid",
				remainig_days: null,
				duration: 0,
				duration_type: 1,
				day_type: 2,
				reason: null,
			}),
			allocated_days: null,
			balance: null,
			login_user: null,
		};
	},
	watch: {
		start_date() {
			this.categoryDays();
		},
		leaveUpdateData: {
			handler(param) {
				if (param) {
					this.init(param);
				}

				/* this.getCategoryOptions(param.staff.id); */
			},
		},
		"leaveCreate.duration_type": {
			handler(param) {
				if (param == 1) {
					this.leaveCreate.day_type = 2;
				}
				if (param == 2) {
					this.leaveCreate.day_type = 1;
				}
			},
		},
	},
	methods: {
		test(row) {
			if (row.balance == 0 && row.category != "UnPaid") {
				this.blankdate = true;
			} else {
				this.blankdate = false;
			}
		},
		goToUserDetail(userId = 0) {
			if (!userId) {
				return false;
			}
			this.$router.push({
				name: `user-detail`,
				params: {
					id: userId,
				},
			});
		},
		calculateDate(data, category = null) {
			if (category == "category") {
				/* Clear dates when category change */
				this.start_date = null;
				this.end_date = null;
				return false;
			}

			if (category == "start_date" && this.end_date) {
				let startDate = new Date(this.start_date).getTime();
				let endDate = new Date(this.end_date).getTime();
				if (startDate > endDate) {
					this.end_date = null;
					return false;
				}
			}

			let lastDay = MomentJS(this.end_date);
			let firstDay = MomentJS(this.start_date);
			if ((firstDay.day() == 0 || firstDay.day() == 6) && this.leaveCreate.category != "UnPaid") {
				this.start_date = null;
				this.formValid = false;
				this.$store.commit(SET_ERROR, [
					{ message: "You cannot select Saturday or Sunday.", model: true },
				]);
				// console.log("First day => ",firstDay.day(), this.formValid);
				// return false;
			} else {
				if (data.id == null && data.category != "UnPaid") {
					let calcBusinessDays =
						1 + (lastDay.diff(firstDay, "days") * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;
					if (lastDay.day() == 6) calcBusinessDays--; //SAT
					if (firstDay.day() == 0) calcBusinessDays--; //SUN
					if (this.leaveCreate.duration == 1) {
						calcBusinessDays = calcBusinessDays - 0.5;
					}

					this.totalDuration = calcBusinessDays; /* Holidays needs */

					if (
						this.remainingLeaves &&
						this.remainingLeaves > 0 &&
						this.remainingLeaves < this.totalDuration
					) {
						this.leaveCreate.duration = 1;
						this.diableDay = true;
					} else {
						this.leaveCreate.duration = 0;
						this.diableDay = false;
					}
				}
				//set end date start if its empty
				if (this.end_date == null) {
					this.end_date = this.start_date;
				}
				//this.getBusinessDays();
			}
		},
		getBusinessDays() {
			var lastDay = MomentJS(this.end_date);
			var firstDay = MomentJS(this.start_date);
			let holidaysInBetween = 0;
			let totalDaysSelected = 0;
			let calcBusinessDays =
				1 + (lastDay.diff(firstDay, "days") * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;
			if (lastDay.day() == 6) calcBusinessDays--; //SAT
			if (firstDay.day() == 0) calcBusinessDays--; //SUN

			totalDaysSelected = calcBusinessDays; //for loop to check holiday exist

			if (this.leaveCreate.duration == 1) {
				calcBusinessDays = calcBusinessDays - 0.5;
			}

			if (this.holiday.length && this.holiday.includes(firstDay)) {
				holidaysInBetween++;
			}

			for (let k = 0; k < parseInt(totalDaysSelected); k++) {
				var dateNew = firstDay.format("YYYY-MM-DD");
				if (k != 0) dateNew = firstDay.add(1, "days").format("YYYY-MM-DD");

				if (this.holiday.length && this.holiday.includes(dateNew)) {
					holidaysInBetween++;
				}
			}
			this.holidaysInBetween = holidaysInBetween;
			//console.log(this.holidaysInBetween);
			if (holidaysInBetween) {
				calcBusinessDays = calcBusinessDays - holidaysInBetween;
			}

			return calcBusinessDays;
		},
		getProvision(proDate) {
			let a = MomentJS();
			let b = MomentJS(proDate);
			this.probisionTime = b.diff(a, "days");
		},
		changeData(data) {
			if (data == true) {
				this.leaveCreate.duration = 1;
				this.leaveCreate.duration_type = 1;
			} else {
				this.leaveCreate.duration = 0;
				this.leaveCreate.duration_type = 0;
				this.leaveCreate.day_type = 0;
			}
			if (this.start_date && this.end_date) {
				this.getBusinessDays();
			}
		},
		init(param) {
			if (param && param.id) {
				this.leaveCreate = {
					id: param.id,
					staff: (param.staff && param.staff.id) || null,
					category: (param.category && param.category) || null,
					duration: param.duration || 0,
					duration_type: param.duration_type || 1,
					reason: param.reason || null,
					day_type: param.day_type || 1,
					//category : param.category || null
				};
				this.start_date = param.start_date || null;
				this.end_date = param.end_date || null;

				let staff_id = this.leaveCreate.staff;

				if (staff_id > 0) {
					this.getUser(staff_id);
					this.getCategoryOptions(staff_id);
					/* this.categoryDays(); */
				}
			}
		},
		getMaxDate(mindate, remainig_days) {
			if (remainig_days <= 0) {
				return MomentJS(mindate, "YYYY-MM-DD")
					.add(Math.floor(remainig_days) - 1, "days")
					.format("YYYY-MM-DD");
			}
			return null;
		},
		currentUserData() {
			const user = getCurrentUser();
			// if (user && user.role != "Admin") {
			if (user) {
				this.login_user = user;
				if (!objectPath.get(user, "users_type")) {
					this.login_user = { ...user, users_type: "super_admin" };
				}
			}
			this.leaveCreate.staff = user.id;
			this.getCategoryOptions(user.id);
			// }
			// return user;
		},
		leaveSetting() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: `leave-setting`,
				})
				.then((data) => {
					this.userList = data.staff;
					this.holiday = data?.holiday;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		categoryDays() {
			const _this = this;
			let categoryData = filter(_this.categoryList, function (row) {
				return row.category === _this.leaveCreate.category;
			});

			if (categoryData[0] && categoryData[0].balance) {
				this.getBusinessDays();
				let maxDate = toString(
					MomentJS(this.start_date, "YYYY-MM-DD")
						.add(
							Math.ceil(toSafeInteger(categoryData[0].balance) + toSafeInteger(this.weekEndDays)) - 1,
							"days"
						)
						.format("YYYY-MM-DD")
				);

				var lastDay = MomentJS(maxDate);
				var firstDay = MomentJS(this.start_date);
				let calcBusinessDays =
					1 + (lastDay.diff(firstDay, "days") * 5 - (firstDay.day() - lastDay.day()) * 2) / 7;
				if (lastDay.day() == 6) calcBusinessDays--; //SAT
				if (firstDay.day() == 0) calcBusinessDays--; //SUN

				let addWeekends = categoryData[0].balance - calcBusinessDays;

				if (addWeekends > 0) {
					this.weekEndDays = this.weekEndDays + addWeekends;
					this.categoryDays();
				} else {
					if (this.holidaysInBetween > 0) {
						maxDate = MomentJS(maxDate, "YYYY-MM-DD")
							.add(this.holidaysInBetween, "days")
							.format("YYYY-MM-DD");
					}

					this.weekEndDays = 0;
					this.maxDate = maxDate;
					this.remainingLeaves = categoryData[0].balance;
					this.apprveLeaves = categoryData[0].taken;
					this.pendingLeaves = categoryData[0].pending;
					this.allocated = categoryData[0].allocated_days;
				}
			}
		},
		getCategoryOptions(userList = 0) {
			/* To avoid selection of leaves other than unpaid in case of user change. They might have 0 (Zero) leave balance of other leaves. */
			this.leaveCreate.category = "UnPaid";

			if (userList == 0) {
				const user = getCurrentUser();
				if (user && user.role == "Staff") {
					userList = user.id;
				} else {
					return false;
				}
			}

			if (userList > 0) {
				this.pageLoading = true;
				const _this = this;
				_this.categoryDays();
				_this.$store
					.dispatch(GET, {
						url: "setting/leave/option/" + userList,
					})
					.then((data) => {
						_this.getUser(userList);

						const leaveLists = data.categories.map((row) => {
							return {
								category_id: row.id,
								id: row?.user_leave[0]?.id,
								maxdays: row.maxdays,
								category: row.category,
								taken: row?.user_leave[0]?.taken,

								earn_leave: row?.user_leave[0]?.earn_balance,
								pending: row?.user_leave[0]?.pending,
								balance: row?.user_leave[0]?.allocated_days - row?.user_leave[0]?.taken,
							};
						});
						_this.categoryList = leaveLists;
						console.log(_this.categoryList, "_this.categoryList");

						_this.categoryList.push({
							category: "UnPaid",
						});

						if (this.leaveCreate.id == null) {
							_this.categoryDays();
						}
					})
					.catch((error) => {
						_this.logError(error);
					})
					.finally(() => {
						_this.pageLoading = false;
						if (!_this.leaveCreate.category) {
							_this.leaveCreate.category = "UnPaid";
						}
					});
			}
			/* 	this.categoryDays(); */
		},
		getUser(id) {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, { url: `single-user/${id}` })
				.then((data) => {
					this.userData = data;
					//console.log(data);
					this.getProvision(this.userData.provision_date);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		CreateOrUpdateLeave() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs["leaveForm"]);
			_this.$refs["leaveForm"].validate();
			if (formErrors.length) {
				_this.errors = [];
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}
			if (!_this.$refs["leaveForm"].validate()) {
				return false;
			}
			let leaveId = _this.leaveCreate.id || 0;
			let staff = _this.leaveCreate.staff || null;
			if (!staff) {
				const user = getCurrentUser();
				staff = user.id;
			}
			if (this.leaveCreate.duration == true) {
				this.leaveCreate.duration = 1;
			} else {
				this.leaveCreate.duration = 0;
			}
			let remainigBalanceData = find(this.categoryList, { category: this.leaveCreate.category });

			if (
				remainigBalanceData &&
				remainigBalanceData.balance == 0 &&
				this.leaveCreate.category != "UnPaid"
			) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Sorry ! You don't have any leave balance for this leave type" },
				]);
				return false;
			}
			this.pageLoading = true;
			let formData = new FormData();
			formData.append("staff", this.leaveCreate.staff ? this.leaveCreate.staff : 0);
			formData.append("category", this.leaveCreate.category ? this.leaveCreate.category : null);
			/* formData.append("remaining", this.remainingLeaves ? this.remainingLeaves:0);
				console.log(this.remainingLeaves) */
			/*	formData.append("pending", this.pendingLeaves ? this.pendingLeaves:0);
				formData.append("allocated", this.allocated ? this.allocated:0); */
			formData.append("duration", this.leaveCreate.duration ? this.leaveCreate.duration : 0);
			formData.append(
				"duration_type",
				this.leaveCreate.duration_type ? this.leaveCreate.duration_type : 0
			);
			if (this.leaveCreate && this.leaveCreate.reason) {
				formData.append("reason", this.leaveCreate.reason ? this.leaveCreate.reason : null);
			}
			if (this.start_date) {
				formData.append("start_date", this.start_date ? this.start_date : null);
			}

			if (this.leaveCreate && this.leaveCreate.day_type) {
				formData.append("day_type", this.leaveCreate.day_type ? this.leaveCreate.day_type : 0);
			}
			if (this.end_date) {
				formData.append("end_date", this.end_date ? this.end_date : null);
			}
			if (this.files && this.files.length) {
				for (let i = 0; i < this.files.length; i++) {
					if (this.files && this.files[i] && this.files[i].file) {
						formData.append(`file[${i}][file]`, this.files[i].file);
						formData.append(`file[${i}][name]`, this.files[i].name);
					}
				}
			}
			let requestTYPE = POST;
			let requestURL = "leave";

			if (leaveId > 0) {
				requestURL = `leave/${leaveId}`;
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (leaveId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Leave updated successfully." },
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Leave added successfully." },
						]);
					}

					LeaveEventBus.$emit("refresh", { page: 1 });
					this.$emit("refreshCount", true);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		/* getLeave() {
			const _this = this;
			_this.pageLoading = true;
			_this.$store
				.dispatch(GET, {
					url: "leave/" + _this.leave,
				})
				.then(({ data }) => {
					_this.leaveCreate = new Object({
						staff: data.staff ? data.staff.staff : null,

						duration: data.duration,
						duration_type: data.duration_type,
						reason: data.reason,
						day_type: data.day_type,
						status: data.status,
					});
					_this.start_date = data.start_date;
					_this.end_date = data.end_date;
				})
				.catch((error) => {
					_this.logError(error);
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		}, */
	},
	created() {
		if (this.leaveCreate.staff > 0) {
			this.getCategoryOptions();
		}
	},

	mounted() {
		const _this = this;
		_this.leaveSetting();
		/* _this.categoryDays(); */
		_this.getProvision(this.userData.provision_date);
		_this.init(this.leaveUpdateData);
		_this.leave = toSafeInteger(_this.$route.params.id);
		this.currentUserData();

		this.minDate = MomentJS(new Date()).subtract("days", 7).format("YYYY-MM-DD");

		/* new Date().toISOString().substr(0, 10) */
	},

	computed: {
		is_staff() {
			const user = this.currentUserData();
			const role_name = user.role;
			return role_name != "Admin";
		},
	},
};
</script>
