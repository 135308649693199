<template>
	<v-sheet class="main-detail">
		<div class="">
			<v-layout class="section-main-content">
				<div class="listing-sidebar border-right">
					<div class="listing-header d-flex">
						<v-menu offset-y right bottom content-class="elevation-3" rounded="0">
							<template v-slot:activator="{ on, attrs }">
								<p class="sub-heading m-0" v-on="on" v-bind="attrs">
									All Leaves
									<v-icon>mdi-menu-down</v-icon>
								</p>
							</template>

							<v-list>
								<v-list-item-group>
									<v-list-item
										v-for="(status, index) in leaveStatus"
										:key="index"
										v-on:click="statusFilterData(status.value)"
									>
										<v-list-item-title class="d-flex align-items-center">
											{{ status.text }} <span class="circle" :class="status.status_color"></span>
											<v-spacer></v-spacer>
											<div class="cm-budge white--text" :class="status.status_color">
												{{ status.status_count }}
											</div>
										</v-list-item-title>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-menu>
						<v-spacer></v-spacer>
						<v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text mr-2"
							@click="search_open = !search_open"
						>
							<v-icon class="icon-16px"> fa fa-search </v-icon></v-btn
						>
						<!-- <v-btn
							depressed
							tile
							color="blue darken-4"
							class="white--text"
							v-on:click="open_dialog.create_leave = true"
						>
							<v-icon>mdi-plus</v-icon> New</v-btn
						> -->
					</div>
					<div class="sidebarsearch-wrapper" :class="search_open ? 'open' : ''">
						<div class="sidebarsearch">
							<v-text-field
								hide-details
								outlined
								v-model="search"
								placeholder="Search..."
								v-on:keyup="searchLeave($event)"
							>
								<v-icon slot="append" @click="closeSerach()"> mdi-close </v-icon>
							</v-text-field>
						</div>
					</div>

					<div class="side-listing listing-sidebar-list">
						<div class="listing-innerlist">
							<div
								v-for="(data, index) in filterData"
								:key="index"
								:class="`list-items ${data.id == leaveId ? 'active' : ''}`"
								v-on:click="tabDetails(data.id)"
							>
								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Staff : </span>
										<span class="fw-500">{{ data.display_name }}</span>
									</div>
								</div>
								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">Start Date : </span>
										<span class="fw-500">{{ data.leave_start_date }}</span>
									</div>
								</div>
								<div class="ellipsis pr-6">
									<div class="ellipsis pr-6">
										<span class="fw-600 pr-2">End Date : </span>
										<span class="fw-500">{{ data.leave_end_date }}</span>
									</div>
								</div>
								<div class="pr-6">
									<div class="pr-6">
										<span class="fw-600 pr-2">Leave Day : </span>
										<v-chip label x-small white--text class="blue">
											{{ data.leave_days.toUpperCase().replaceAll("_", " ") }}
										</v-chip>
									</div>
								</div>
								<div class="pr-6">
									<div class="pr-6">
										<span class="fw-600 pr-2">Reason : </span>
										<span class="fw-500">{{ data.leave_reason }}</span>
									</div>
								</div>
								<v-checkbox hide-details class="ma-0 pa-0 size-16"> </v-checkbox>
							</div>
						</div>
					</div>
					<!-- customer details -->

					<!-- customer details end -->
				</div>
				<div class="details-body">
					<div class="p-sticky bg-white">
						<v-layout class="align-center light-blue-bg px-4 py-2 flex-wrap">
							<v-flex md12>
								<div class="d-flex">
									<p class="my-auto font-level-3-bold">
										<span class="fw-600 mr-2">Staff Name : </span>
										<span class="fw-500" v-if="leaveData">{{ leaveData.display_name }}</span>
									</p>
									<v-spacer></v-spacer>
									<div v-if="leaveTab == 'overview'">
										<v-btn
											depressed
											tile
											class="blue darken-4 my-auto"
											color="white--text "
											v-on:click="open_dialog.create_leave = true"
										>
											<v-icon class="mr-1">mdi-plus</v-icon>
											Create
										</v-btn>
									</div>
								</div>
								<div>
									<div class="row">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Status : </span>
											<v-chip class="mb-1 mr-1" label color="green white--text" x-small v-if="leaveData">
												{{ leaveData.status }}
											</v-chip>
										</div>
										<div class="d-flex mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Department : </span>
											<v-chip class="mr-1" label outlined color="blue white--text" x-small v-if="leaveData">
												{{ leaveData.department }}
											</v-chip>
										</div>
									</div>
									<div class="row">
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Total Leave : </span>
											<span class="fw-500" v-if="leaveData">{{ leaveData.total_leave }}</span>
										</div>
										<div class="mt-2 py-0 col-md-3 col-12">
											<span class="fw-600 mr-2">Leave Balence : </span>
											<span class="fw-500" v-if="leaveData">{{ leaveData.balance_leave }}</span>
										</div>
									</div>
								</div>
							</v-flex>
						</v-layout>
						<v-layout class="px-4 py-2">
							<v-tabs
								v-model="leaveTab"
								background-color="transparent"
								color="blue"
								class="custom-tab-transparent w-100"
								active-class="blue darken-4 text-white"
								hide-slider
							>
								<v-tab href="#overview"><v-icon small left>mdi-information-outline</v-icon>Overview</v-tab>
							</v-tabs>
						</v-layout>
					</div>
					<div class="tab-layout-content px-4">
						<v-tabs-items v-model="leaveTab">
							<v-tab-item value="overview">
								<div class="d-flex mt-2">
									<span class="fw-600 mr-2 no-wrap">Leave Day : </span>
									<span>
										<v-chip
											label
											color="blue white--text text-capitalize"
											x-small
											v-if="leaveData.leave_days"
											>{{ leaveData.leave_days.replaceAll("_", " ") }}</v-chip
										>
									</span>
								</div>
								<div class="d-flex mt-2">
									<span class="fw-600 mr-2 no-wrap">Start Date : </span>
									<span v-if="leaveData">{{ formatDate(leaveData.leave_start_date) }}</span>
								</div>
								<div class="d-flex mt-2">
									<span class="fw-600 mr-2 no-wrap">End Date : </span>
									<span v-if="leaveData">{{ formatDate(leaveData.leave_start_date) }}</span>
								</div>
								<div class="d-flex mt-2">
									<span class="fw-600 mr-2 no-wrap">Description : </span>
									<span v-if="leaveData" v-html="leaveData.leave_reason"></span>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</div>
				</div>
			</v-layout>
		</div>
		<!-- sidebar drawers -->
		<CreateLeave
			:drawer="open_dialog.create_leave"
			v-on:close="open_dialog.create_leave = false"
			form-title="Create"
		></CreateLeave>
	</v-sheet>
</template>
<script>
import CreateLeave from "@/view/module/leave/CreateLeave";
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
	name: "Leave-details",
	data() {
		return {
			leaveData: [],
			filterData: [],
			leaveStatus: [],
			leaveId: null,
			search: null,
			search_open: false,
			leaveTab: "overview",
			open_dialog: {
				create_leave: false,
			},
			list1: [
				{
					staff: "Leave title static",
					start_date: "22/04/2022",
					end_date: "22/04/2022",
					reason:
						"I'll not be able to come office today because I am suffering from a cold or high fever",
					halfday: "first half",
					status_color: "orange",
				},
				{
					staff: "Leave title static",
					start_date: "22/04/2022",
					end_date: "22/04/2022",
					reason:
						"I'll not be able to come office today because I am suffering from a cold or high fever",
					halfday: "first half",
					status_color: "orange",
				},
				{
					staff: "Leave title static",
					start_date: "22/04/2022",
					end_date: "22/04/2022",
					reason:
						"I'll not be able to come office today because I am suffering from a cold or high fever",
					halfday: "first half",
					status_color: "orange",
				},
				{
					staff: "Leave title static",
					start_date: "22/04/2022",
					end_date: "22/04/2022",
					reason:
						"I'll not be able to come office today because I am suffering from a cold or high fever",
					halfday: "first half",
					status_color: "orange",
				},
			],
		};
	},
	components: {
		CreateLeave,
	},
	methods: {
		pageTitle() {
			return "Leave Details";
		},
		closeSerach() {
			this.search_open = false;
			this.search = "";
		},
		getSingleLeave() {
			this.$store
				.dispatch(GET, { url: `single-leave/${this.leaveId}` })
				.then((data) => {
					this.leaveData = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		leaveSetting() {
			this.$store
				.dispatch(GET, {
					url: `leave-setting`,
				})
				.then((data) => {
					this.leaveStatus = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getAllLeave() {
			this.$store
				.dispatch(QUERY, { url: `leave` })
				.then((data) => {
					this.filterData = data.tbody;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		statusFilterData(value) {
			this.$store
				.dispatch(QUERY, { url: `leave`, data: { status: value } })
				.then((data) => {
					this.filterData = data.tbody;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		tabDetails(id) {
			this.$router.push({
				name: "leave-detail",
				params: { id },
			});
			this.leaveId = id;
			this.getSingleLeave();
		},
		searchLeave($event) {
			const keySearch = $event.target.value;

			if (keySearch !== "") {
				this.$store
					.dispatch(QUERY, { url: `leave`, data: { search: keySearch } })
					.then((data) => {
						this.filterData = data.tbody;
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
	},
	mounted() {
		this.getSingleLeave();
		this.leaveSetting();
		this.getAllLeave();

		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Leave", disabled: false, href: "/leave" },
			{ text: "Detail", disabled: true },
		]);
	},
	beforeMount() {
		this.leaveId = this.$route.params.id;
	},
};
</script>
